<template>
  <v-app>
    <v-app-bar app>
      <v-btn plain to="/"><v-icon>mdi-home</v-icon></v-btn>
      <span class="text-body-2 ml-2">Service Status</span>
        <v-icon v-if="apiStatus === 'NORMAL'" small color="green" class="ml-1">mdi-circle</v-icon>
        <v-icon v-else small color="red" class="ml-1">mdi-circle</v-icon>
      <v-spacer></v-spacer>
      <v-btn v-if="$root.$data.currentUser.length === 0" to="/login">登录</v-btn>
      <span v-else class="body-1">{{$root.$data.currentUser}}</span>
    </v-app-bar>
    <v-main>
        <router-view />
      <v-snackbar centered :timeout="$root.$data.pageToastTimeout" v-model="$root.$data.showPageToast">{{$root.$data.pageToast}}</v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import {CommonApiService} from "./service/CommonApiService";

const apiService = new CommonApiService();

export default Vue.extend({
  data:()=>(
      {
        apiStatus: "fetching",
      }
  ),
  created() {
    apiService.getApiStatus().then(status=>{
      this.apiStatus = status;
    });
  },
  methods:{
  }

});
</script>

<style lang="scss">

</style>
