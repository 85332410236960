import {IULockPasswordResponse} from "../../../common/types/ResponseType";

export default class IULockApiService {

    public static async generateTempPassword(startTime :string, managerPassword: string):Promise<IULockPasswordResponse>{
        const response = await fetch('/api/iulock/temp-password',
            {
                method: 'post',
                body: JSON.stringify({
                    startTime: startTime,
                    managerPassword: managerPassword
                }),
                headers:{
                    'content-type': 'application/json'
                }
            }
            );
        return await response.json() as IULockPasswordResponse;
    }
}