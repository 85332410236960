import Vue from "vue";

export default Vue.extend({
    methods:{
        showToast(message:string,timeOut = 3000){
            console.log("show toast:", message, timeOut);
            this.$root.$data.pageToast = message;
            this.$root.$data.pageToastTimeOut = timeOut;
            this.$root.$data.showPageToast = true;
        },
        hideToast(){
            this.$root.$data.showPageToast = false;
        }
    }
});