













// @ is an alias to /src
import {HomePageCard} from "../../../common/types/ResponseType";
import {CommonApiService} from "@/service/CommonApiService";
import MixinVue from "../../MixinVue";

export default MixinVue.extend({
  name: 'Home',
  data: ()=>({
    'cardsData': [] as HomePageCard[]
  }),
  created() {
    this.loadHomePageData();
  },
  methods:{
    async loadHomePageData(){
      const response = await CommonApiService.getHomePageData();
      if(response.code !== 0 || !response.data){
        this.showToast(!response.message?"unknown error": response.message, 5000);
        return;
      }
      this.cardsData = response.data.cards;
    }
  }
});
