import {CurrentUserResponse, HomePageInfoResponse, LoginResponse} from "../../../common/types/ResponseType";


export type ApiStatus="NORMAL"|"ERROR"|"STOPPED";

export interface ApiStatusResponse{
    code: number,
    message: string,
}


export class CommonApiService{


    public async getApiStatus(): Promise<ApiStatus>{
        let status:ApiStatus;
        try{
            const response:Response = await fetch('/api/status');
            const result: ApiStatusResponse = await response.json();
            status = result.code === 0 ? "NORMAL" : "ERROR";
        }catch (e) {
            console.log("fetch api status exception:", e)
            status = "STOPPED";
        }

        return status;
    }

    public async doLogin(userName: string, password: string): Promise<LoginResponse>{
        const params = {
            userName:userName,
            password: password
        };
        const response = await CommonApiService.postJsonData('/api/login', params);
        return response as LoginResponse;
    }

    public async getCurrentUser(): Promise<CurrentUserResponse>{
        const response:Response = await fetch('/api/current_user');
        return await response.json() as CurrentUserResponse;
    }

    public static async getHomePageData(): Promise<HomePageInfoResponse>{
        const response:Response = await fetch('/api/homepage');
        return await response.json() as HomePageInfoResponse;
    }

    public static async postJsonData(url: string, postData: object){
        const response = await fetch(url, {
            method:'POST',
            body: JSON.stringify(postData),
            headers:{
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }


}