
































import {LoginResponse} from "../../../common/types/ResponseType";
import {CommonApiService} from "@/service/CommonApiService";
import Vue from "vue";

const commonApiService:CommonApiService = new CommonApiService();

export default Vue.extend( {
  name: "Login",
  data: ()=>({
    userName: "",
    password: "",
    showAlert: false,
    alertMessage: ""
  }),
  methods:{
    login: async function(){
        let loginResponse:LoginResponse = await commonApiService.doLogin(this.userName, this.password);
        if(loginResponse.code != 0 || !loginResponse.data){
          console.log("login error:", loginResponse);
          this.alertMessage = loginResponse.message === undefined ? "unknown error" : loginResponse.message;
          this.showAlert = true;
        }else{
          // @ts-ignore
          this.$cookie.set('token', loginResponse.data.token, {expires:loginResponse.data.expire + 's'});
          this.$router.replace("/");
        }
    }
  }
})
