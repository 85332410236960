import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueCookie from 'vue-cookie'
import {CommonApiService} from "./service/CommonApiService";

Vue.config.productionTip = false
Vue.use(VueCookie)

const sourceData = {
  currentUser: "",
  showPageToast: false,
  pageToast:"",
  pageToastTimeOut: 5000
};


router.afterEach(async (to, from)=>{
  if(to.path !== '/login'){
    await fetchCurrentUser();
  }
  console.log("routing finished:", from,to);
});


const vue = new Vue({
  vuetify,
  router,
  render: h => h(App),
  data:sourceData,
});

vue.$mount('#app');

async function fetchCurrentUser(){
  const apiService = new CommonApiService();
  const currentUserResponse = await apiService.getCurrentUser();
  if(currentUserResponse.code === 0){
    vue.$data.currentUser = currentUserResponse.data.userName;
  }else{
    vue.$data.currentUser = '';
  }
}
