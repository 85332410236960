






















































import moment from "moment";
import IULockApiService from "@/service/IULockApiService";
import {VForm} from "@/types/vuetify";
import MixinVue from "../../MixinVue";

const LOCAL_STORAGE_KEY_PASSWORD = 'local_password';

export default MixinVue.extend({
  name: "IULock",
  data: ()=>({
    startDate: '0000-00-00',
    startTime: '00:00',
    password: '',
    showPassword: false,
    generating: false,
    generatedPassword: '',
    effectiveTime: '0000-00-00 00:00',
    effectiveEndTime: '0000-00-00 00:00',
    dateRules:[
      (v:string)=> !!v || 'startDate is Required',
      (v:string)=> moment(v).isValid() || 'startDate Format error'
    ],
    timeRules:[
      (v:string)=> !!v|| 'startTime is Required',
      (v:string)=> /^\d\d:\d\d$/.test(v) || 'startTime Format error'
    ],
    passwordRules:[
      (v:string) =>!!v|| 'password is Required',
      (v:string) => /^\d{6,8}$/.test(v)||'6-8 digits'
    ],
  }),
  computed:{
    form():VForm{
      return this.$refs.generatePwdForm as VForm;
    },
  },
  created() {
    this.initFormValue();
  },
  methods:{
      generatePassword(){
        this.clearGenerated();
        if(!this.form.validate()){
          console.log("form validation failed")
          return;
        }
        localStorage.setItem(LOCAL_STORAGE_KEY_PASSWORD, this.password);
        this.generating = true;
        IULockApiService.generateTempPassword(`${this.startDate} ${this.startTime}`, this.password)
        .then(response=>{
          if(response.code !== 0 || !response.data){
            this.showToast(response.message? response.message: "Unknown Error");
            return;
          }
          this.generatedPassword = response.data.password;
          this.effectiveTime = response.data.startTime;
          this.effectiveEndTime = response.data.endTime;
        }).catch(e=>{
          console.log("request error found", e);
          this.showToast('Request Error:'+ e.message);
        }).finally(()=>{
          this.generating = false;
        })
      },
      initFormValue(){
        this.startDate = moment().format('YYYY-MM-DD');
        this.startTime = moment().format('HH:mm');
        let savedPassword = localStorage.getItem(LOCAL_STORAGE_KEY_PASSWORD);
        this.password = savedPassword == null? '' : savedPassword;
      },
      clearGenerated(){
        this.generatedPassword = '';
        this.effectiveTime = '0000-00-00 00:00';
        this.effectiveEndTime = '0000-00-00 00:00';
      }
  }
});
